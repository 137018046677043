import React from "react";
import { Box, Card, CardContent, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { useGetReturnedProductsQuery } from "../../redux/Apis/Order"; // Adjust the import based on your project structure
import "./Returns.css"; // Import your CSS for styling

const Returns = () => {
  const { isLoggedIn, userId } = useSelector((state) => state.authSlice);

  // Fetch returned products using the query hook
  const { data, error, isLoading } = useGetReturnedProductsQuery(
    { user_id: userId },
    { skip: !isLoggedIn }
  );

  return (
    <Box className="returns-container">
      <Typography variant="h4" className="title">
        Returned Products
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      {isLoading ? (
        <Typography variant="h6" className="no-returns">
          Loading...
        </Typography>
      ) : error ? (
        <Typography variant="h6" className="no-returns">
          Error loading returns. Please try again later.
        </Typography>
      ) : data?.totalReturnedProducts === 0 ? (
        <Typography variant="h6" className="no-returns">
          No returns available.
        </Typography>
      ) : (
        data.data.map((item, index) => (
          <Card variant="outlined" className="return-card" key={index}>
            <CardContent className="card-content">
              <Typography variant="h6" className="card-title">
                Order ID: {item.orderId}
              </Typography>
              <Typography variant="subtitle1" className="card-subtitle">
                Product ID: {item.productId}
              </Typography>
              <Typography className="card-text">
                Return Reason: {item.returnReason}
              </Typography>
              <Typography className="card-text">
                Return Message: {item.returnMessage}
              </Typography>
              <Typography className="card-text">
                Status: {item.returnStatus}
              </Typography>
              <Typography className="card-text">
                User: {item.user.first_name} {item.user.last_name}
              </Typography>
              <Typography className="card-text">
                Email: {item.user.email}
              </Typography>
            </CardContent>
          </Card>
        ))
      )}
    </Box>
  );
};

export default Returns;
