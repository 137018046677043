import React, { useState, useEffect } from "react";
import {
  // Rating,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Button,
  TextField,
  Typography,
  Box,
  // Chip,
  // Tooltip,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../../redux/Slices/Cart/index";
import { openCartModal } from "../../../redux/Slices/CartModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./ProductInformation.css";

const ProductInformation = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const displayCards = true;

  const selectedVariation = data?.price_variations?.[0] || null;
  // const [selectedColor, setSelectedColor] = useState(
  //   data?.variants?.[0]?.options?.[0]?.option || "N/A"
  // );
  const [quantity, setQuantity] = useState(1);
  // const [calculatedPrice, setCalculatedPrice] = useState(0);
  // const [availableStock, setAvailableStock] = useState(0);

  // const calculatePrice = () => {
  //   if (!selectedVariation) return;

  //   const basePrice = parseFloat(selectedVariation.price);
  //   const discount = data?.discount
  //     ? (basePrice * parseFloat(data.discount.discount_percentage)) / 100
  //     : 0;

  //   const finalPrice = basePrice - discount;
  //   setCalculatedPrice(finalPrice);

  //   // Update available stock for the selected variation
  //   const selectedOption = data.variants[0]?.options.find(
  //     (option) => option.option === selectedColor
  //   );
  //   setAvailableStock(selectedOption ? selectedOption.quantity : 0);
  // };

  // useEffect(() => {
  //   if (data?.price_variations?.length > 0) {
  //     setSelectedVariation(data.price_variations[0]);
  //   }
  //   if (data?.variants?.length > 0) {
  //     setSelectedColor(data.variants[0].options[0].option);
  //   }
  // }, [data]);

  // const handleVariationSelect = (variation) => {
  //   setSelectedVariation(variation);
  // };

  // const handleColorSelect = (color) => {
  //   setSelectedColor(color);
  //   calculatePrice(); // Recalculate price when color changes
  // };

  const handleQuantityChange = (event) => {
    const newQuantity = Math.max(1, parseInt(event.target.value, 10));
    setQuantity(newQuantity);
  };

  const handleAddToCart = () => {
    if (!selectedVariation) {
      toast.error(t("Please select a variation"));
      return;
    }

    // if (quantity > availableStock) {
    //   toast.error(t("Quantity exceeds available stock"));
    //   return;
    // }

    const productToAdd = {
      productId: data.id,
      // sku: data.sku,
      name: data.name_en,
      // variation: selectedVariation?.scale,
      price: selectedVariation?.price,
      quantity,
      imageUrl: data.images[0] || "",
      // color: selectedColor,
    };

    dispatch(addItemToCart(productToAdd));
    dispatch(openCartModal());
  };

  return (
    <Box
      className="product-information"
      sx={{ padding: "16px", maxWidth: "800px", margin: "auto" }}
    >
      {/* Basic Information */}
      {displayCards && (
        <div className="product-attributes-bar">
          {data.trending && (
            <div className="product-attribute">
              <span className="attribute-emoji">🔥</span>
              <span className="attribute-text">{t("Trending")}</span>
            </div>
          )}
          {data.best_selling && (
            <div className="product-attribute">
              <span className="attribute-emoji">🏆</span>
              <span className="attribute-text">{t("Best Selling")}</span>
            </div>
          )}
          {data.hot && (
            <div className="product-attribute">
              <span className="attribute-emoji">🌶️</span>
              <span className="attribute-text">{t("Hot")}</span>
            </div>
          )}
          {data.featured && (
            <div className="product-attribute">
              <span className="attribute-emoji">⭐</span>
              <span className="attribute-text">{t("Featured")}</span>
            </div>
          )}
          {data.new_arrival && (
            <div className="product-attribute">
              <span className="attribute-emoji">🆕</span>
              <span className="attribute-text">{t("New Arrival")}</span>
            </div>
          )}
        </div>
      )}

      <Typography variant="h4" gutterBottom>
        {data.name_en}
      </Typography>
      {/* <Typography variant="body1" color="text.secondary" gutterBottom>
        {data.description_en}
      </Typography> */}
      {/* 
      <Box sx={{ marginBottom: "16px" }}>
        <Typography variant="body2">
          <p className="product-brand">Description: {data?.brand_name}</p>{" "}
        </Typography>
      </Box> */}

      <Box>
        <Typography variant="body2">
          <p className="product-brand">Brand: {data?.brand_name}</p>{" "}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
          {/* {t("Selected Variation Price:")} */}
        </Typography>
        <Typography variant="h4" color="secondary">
          {selectedVariation && selectedVariation.price
            ? `${selectedVariation.price} ${t("USD")}`
            : t("No variation selected")}
        </Typography>
      </Box>

      {/* <Box sx={{ marginBottom: "16px" }}>
        <Typography variant="h6">{t("Price:")}</Typography>
        <Typography variant="h5" color="primary">
          {calculatedPrice.toFixed(2)} {t("USD")}
        </Typography>
        {data.discount && (
          <Typography variant="body2" color="text.secondary">
            {t("Discount:")} {data.discount.discount_percentage}%{" "}
            {t("valid until")}{" "}
            {new Date(data.discount.end_date).toLocaleDateString()}
          </Typography>
        )}
      </Box> */}

      {/* Price Variations */}
      {/* <Box>
        <Typography className="mt-3" variant="h6" gutterBottom>
          {t("Select a variation")}
        </Typography>
        <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          {data?.price_variations?.map((variation) => (
            <Button
              key={variation.id}
              variant={
                selectedVariation?.id === variation.id
                  ? "contained"
                  : "outlined"
              }
              onClick={() => handleVariationSelect(variation)}
              sx={{ textTransform: "capitalize" }}
            >
              {variation.scale} - {variation.price} {t("USD")}
            </Button>
          ))}
        </Box>
      </Box> */}

      {/* Variants */}
      {/* {data?.variants?.length > 0 && (
        <Box className="mt-3 mb-3">
          <Typography variant="h6" gutterBottom>
            {t("Select a variant")}
          </Typography>
          <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {data?.variants[0]?.options?.map((option) => (
              <Chip
                key={option.id}
                label={`${option.option} (${t("Qty")}: ${option.quantity})`}
                color={selectedColor === option.option ? "primary" : "default"}
                onClick={() => handleColorSelect(option.option)}
              />
            ))}
          </Box>
        </Box>
      )} */}

      {/* Discounts */}
      {data.discount &&
        (() => {
          const currentDate = new Date();
          const startDate = new Date(data.discount.start_date);
          const endDate = new Date(data.discount.end_date);

          // Check if the discount is valid (current, future, or started within 2 days before)
          const isDiscountValid =
            (currentDate >= startDate && currentDate <= endDate) ||
            (currentDate < startDate &&
              (startDate - currentDate) / (1000 * 60 * 60 * 24) <= 2);

          return isDiscountValid ? (
            <Box
              className="mt-3"
              sx={{ marginBottom: "16px", color: "success.main" }}
            >
              <Typography variant="body2">
                {t("Discount")}: {data.discount.discount_percentage}%{" "}
                {t("valid until")} {endDate.toLocaleDateString()}
              </Typography>
            </Box>
          ) : null;
        })()}

      {/* Quantity Discounts */}
      {data?.quantity_discounts?.length > 0 && (
        <Box sx={{ marginBottom: "16px" }}>
          <Typography variant="h6" gutterBottom>
            {t("Quantity Discounts")}
          </Typography>
          <ul style={{ paddingLeft: "16px" }}>
            {data?.quantity_discounts?.map((discount) => (
              <li key={discount.id}>
                {t("Buy")} {discount?.quantity} {t("or more")}:{" "}
                {discount?.discount}% {t("off")}
              </li>
            ))}
          </ul>
        </Box>
      )}

      {/* Stock and Quantity */}
      <Box sx={{ marginBottom: "16px" }}>
        <Typography variant="body2">
          {t("Available Stock")}: {data?.stock?.quantity}
        </Typography>
        <TextField
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          inputProps={{ min: 1 }}
          sx={{ marginTop: "8px", width: "100px" }}
        />
      </Box>

      {/* Add to Cart */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddToCart}
        disabled={data.out_of_stock}
        sx={{ marginTop: "16px", width: "100%" }}
      >
        {data.out_of_stock ? t("Out of Stock") : t("Add to Cart")}
      </Button>
    </Box>
  );
};

export default ProductInformation;
