import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import productImage from "../../shared/images/no-image.jpg";
import "./ProductCard.css";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTranslation } from "react-i18next";
import { addItemToCart } from "../../redux/Slices/Cart";
import { openCartModal } from "../../redux/Slices/CartModal";
import { useDispatch } from "react-redux";

// const mockProductData = {
//   id: "12345",
//   name: "Sample Product",
//   name_en: "Sample Product EN",
//   price: 99.99,
//   rating: 4,
//   images: [productImage],
// };

const ProductCard = ({ data }) => {
  // const data = mockProductData;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(productImage);

  useEffect(() => {
    const img = new Image();
    img.src = data?.images?.[0] || productImage;
    img.onload = () => setImageSrc(data?.images[0]); // Valid image URL
    img.onerror = () => setImageSrc(productImage); // Fallback to default image
  }, [data?.images]);

  // Navigate to product details page
  const handleCardClick = () => {
    navigate(`/product/${data?.id}`);
  };

  // Render product rating stars
  const renderStars = (rating) =>
    Array.from({ length: 5 }, (_, i) =>
      i < rating ? (
        <StarIcon key={i} className="star filled" />
      ) : (
        <StarBorderIcon key={i} className="star" />
      )
    );

  // Handle add to cart click
  const handleAddToCart = (e) => {
    e.stopPropagation();
    //  const availableStock = getLatestStockQuantity(data.stock.stocks);
    // Create product object to add to cart with lowest price, random color, and quantity discount
    const productToAdd = {
      productId: data?.id,
      sku: data.sku,
      isBigBuyProduct: !!data.bigbuyId,
      name: data.name_en,
      //  size: lowestVariation.scale,
      price: data?.price_variations[0].price,
      //  parseFloat(lowestVariation.discountedPrice) ||
      //  parseFloat(lowestVariation.priceInSek),

      quantity: 1,
      imageUrl: data.images.length > 0 ? `${data.images[0]}` : productImage,
      //  discount: data.discount ? data.discount.percentage : null, // Include percentage discount if available
      //  quantityDiscount: data.quantity_discount
      //    ? {
      //        // Include quantity discount if available
      //        quantity: data.quantity_discount.quantity,
      //        discount: data.quantity_discount.discount,
      //      }
      //    : null,
    };

    dispatch(
      addItemToCart({
        ...productToAdd,
        //  stock: availableStock, // Include the available stock in the productToAdd object
      })
    );
    dispatch(openCartModal()); // Open cart modal
  };

  return (
    <div className="product-component-card-2" onClick={handleCardClick}>
      <div className="product-image-section-2">
        <img
          src={imageSrc}
          alt={data?.name_en || "Product"}
          style={{
            width: "100%",
            height: "200px",
            objectFit: "",
            minWidth: "100%",
          }}
        />
      </div>
      <div className="product-details-section-2">
        <div className="product-component-info-2">
          <h4 className="product-component-title-2">{data?.name_en}</h4>
          <div className="product-component-rating-2">
            {renderStars(Number(data?.rating) || 0)}
          </div>
        </div>
        <div className="product-component-pricing-2">
          <p className="product-component-price-2">
            {data?.price_variations?.length > 0
              ? `${data?.price_variations[0].price} kr`
              : null}
          </p>
        </div>
        <div className="btn-wrap-2">
          <button className="btn-2 btn-dark-2" onClick={handleAddToCart}>
            <LocalMallOutlinedIcon className="cart-icon" /> {t("ADDCART")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
