import React from "react";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "../components/Privacy/Privacy";

const PrivacyPage = () => {
  return (
    <div>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Minasatkom | Integritetspolicy</title>
        <meta
          name="description"
          content="Läs Minasatkoms integritetspolicy för att förstå hur vi hanterar din data och skyddar din integritet."
        />
        <meta
          name="keywords"
          content="integritetspolicy, Minasatkom, dataskydd, personlig information, sekretess"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Minasatkom | Integritetspolicy" />
        <meta
          property="og:description"
          content="Vi värdesätter din integritet. Läs vår integritetspolicy för detaljer om hur vi samlar in och använder din information."
        />
        <meta property="og:image" content="/path/to/privacy-og-image.jpg" />
        <meta property="og:url" content="https://swedebuy.com/privacy" />
        <meta property="og:type" content="article" />
      </Helmet>

      {/* Privacy Policy Content */}
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPage;
