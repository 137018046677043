import React from "react";
import "./About.css"; // Make sure the CSS is in a separate file like About.css
import Logo from "../../shared/images/new-logo.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const About = () => {
  const { t } = useTranslation();
  const selectedLanguage = useSelector((state) => state.language);

  const isRTL = selectedLanguage === "ar" || selectedLanguage === "kr";

  return (
    <section dir={isRTL ? "rtl" : "ltr"} className="about-section">
      <h2 dir={isRTL ? "rtl" : "ltr"} className="about-heading">
        {t("About")}
      </h2>
      <div className="about-grid">
        <div dir={isRTL ? "rtl" : "ltr"} className="about-text">
          <p className="about-para">{t("About1")}</p>
          <p className="about-para">{t("About2")}</p>
          <p className="about-para">{t("About3")}</p>
        </div>
        <div className="about-image-container">
          <img src={Logo} className="img-about" alt="Minasatkom about" />
        </div>
      </div>
    </section>
  );
};

export default About;
