import React, { useEffect, useRef, useState } from "react";
import ProductDetailsImage from "./ProductDetailsImage";
import "./ProductDetails.css";
import ProductInformation from "./Information/ProductInformation";
import ProductSpecRating from "./ProductSpecRating";
import Product from "../ProductComponent/Product";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetRandomQuery } from "../../redux/Apis/Products";
import HomeProduct from "../ProductComponent/HomeProduct";
import { useTranslation } from "react-i18next";
import { useGetProductReviewsQuery } from "../../redux/Apis/Review";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Slider from "react-slick";
import ProductSlider from "../Trending/ProductSlider";
import Loader from "../Loader/Loader";

const ProductDetails = ({ data, loading }) => {
  // const {
  //   data: randomProductsResponse,
  //   error,
  //   isLoading,
  // } = useGetRandomQuery();
  // const randomProducts = randomProductsResponse?.data || [];
  const { t } = useTranslation();
  // const {
  //   data: dataReviews,
  //   error: error2,
  //   isLoading: isLoading2,
  // } = useGetProductReviewsQuery(data?._id, {
  //   skip: !data?._id,
  // });

  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const isCookieConsentAccepted = useSelector(
    (state) => state.cookieConsent.isAccepted
  );
  const [isLoadingProducts, setIsLoadingProducts] = useState(true); // Loading state

  useEffect(() => {
    if (isCookieConsentAccepted && data?._id) {
      // Retrieve the main recently viewed product IDs cookie
      let productIds = [];
      const mainCookie = Cookies.get("recentlyViewedProducts");
      if (mainCookie) {
        try {
          productIds = JSON.parse(mainCookie);
        } catch (e) {
          console.error("Error parsing main cookie:", e);
        }
      }

      // Ensure productIds is an array
      if (!Array.isArray(productIds)) {
        productIds = []; // Reset to an empty array if data is malformed
      }

      // Check if the current product ID is already in the list
      const isProductStored = productIds.includes(data._id);

      if (!isProductStored) {
        // Add the new product ID to the beginning of the list
        productIds = [data._id, ...productIds];

        // Store updated product IDs in the main cookie
        Cookies.set("recentlyViewedProducts", JSON.stringify(productIds), {
          expires: 7,
        });

        // Store the full product data in a separate cookie using its _id as the key
        Cookies.set(`recentlyViewedProduct_${data._id}`, JSON.stringify(data), {
          expires: 7,
        });

        // Log the updated list of product IDs
      }

      // Load each product's data from its individual cookie
      const recentlyViewedProducts = productIds
        .map((id) => {
          const productCookie = Cookies.get(`recentlyViewedProduct_${id}`);
          return productCookie ? JSON.parse(productCookie) : null;
        })
        .filter((product) => product !== null);

      // Set the recently viewed products state with the retrieved data
      setRecentlyViewedProducts(recentlyViewedProducts);

      // Stop loading once data is available
      setIsLoadingProducts(false);
    }
  }, [isCookieConsentAccepted, data?._id]);
  // Adding data?._id as a dependency to re-run if it changes

  // Example data for customer reviews
  const dummyReviews = [
    {
      reviewer: "Alison Markson",
      rating: 4,
      comment: "A decent product, would recommend.",
    },
    {
      reviewer: "David Smith",
      rating: 5,
      comment: "Absolutely fantastic! Exceeded my expectations.",
    },
  ];

  // const customerReviews =
  //   dataReviews?.totalReviews > 0
  //     ? dataReviews.data.map((review) => ({
  //         reviewer: review.user.fullName,
  //         rating: review.rating,
  //         comment: review.comment,
  //       }))
  //     : [];

  const productSliderRef = useRef(null);
  const reviewsSliderRef = useRef(null);

  const scrollReviewsLeft = () => {
    if (reviewsSliderRef.current) {
      reviewsSliderRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollReviewsRight = () => {
    if (reviewsSliderRef.current) {
      reviewsSliderRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <div className="product-detail-page">
      {/* Category Section */}
      <div className="category-section">
        <Link to={`/products?category_id=${data?.category}`}>
          {data?.category_name}
        </Link>
        <span>/</span> {/* Separator */}
        <Link
          to={`/products?category_id=${data?.category}${
            data?.subcategory ? `&subcategory_id=${data?.subcategory}` : ""
          }`}
        >
          {data?.subcategory_name}
        </Link>
      </div>

      {/* Product Top Section */}
      <div className="product-top-section">
        <div className="product-images-section">
          <ProductDetailsImage images={data?.images} />
        </div>

        <div className="product-extra-section">
          <ProductInformation data={data} />
        </div>
      </div>

      {/* Product Description Section */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="description-content"
          id="description-header"
        >
          <h3>{t("Description")}</h3>
        </AccordionSummary>
        <AccordionDetails>
          <div>{data?.description_en}</div>
        </AccordionDetails>
      </Accordion>

      {/* Customer Reviews Section (Commented Out) */}
      {/* 
      <div className="customer-reviews-section">
        {customerReviews.length > 0 && (
          <>
            <h2>{t("CusRev")}</h2>
            <div className="reviews-slider-container">
              <button
                className="scroll-button left"
                onClick={scrollReviewsLeft}
              >
                &lt;
              </button>
              <div className="reviews-slider" ref={reviewsSliderRef}>
                {customerReviews.map((review) => (
                  <div key={review.id} className="review-card">
                    <p className="reviewer-name">{review.reviewer}</p>
                    <p className="review-rating">{"★".repeat(review.rating)}</p>
                    <p className="review-comment">"{review.comment}"</p>
                  </div>
                ))}
              </div>
              <button
                className="scroll-button right"
                onClick={scrollReviewsRight}
              >
                &gt;
              </button>
            </div>
          </>
        )}
      </div> 
      */}

      {/* People Also Bought Section (Commented Out) */}
      <div className="product-related-section">
        {/* <h2>{t("CusAlso")}</h2> */}
        <div className="product-slider-container">
          {/* Conditionally render based on cookie consent and length of recentlyViewedProducts */}
          {/* 
          <ProductSlider
            products={
              recentlyViewedProducts.length === 1
                ? randomProducts
                : isCookieConsentAccepted
                ? recentlyViewedProducts
                : randomProducts
            }
          />
          */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
