import React, { useState, useRef, useMemo, useEffect } from "react"; // Add React import if missing

import { useGetAllCategoriesWithSubcategoriesQuery } from "../../redux/Apis/Category";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCategories } from "../../redux/Slices/Category/index"; // Action to set categories in Redux
import { icons } from "lucide-react"; // Import all icons
import "./MegaMenu.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MegaMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const { t } = useTranslation();
  // Fetch categories from Redux state
  const storedCategories = useSelector((state) => state.category.categories);
  const [openMenu, setOpenMenu] = useState(false);
  const [allCategoreis, setAllCategories] = useState([]);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  // Fetch categories without skipping based on stored state to ensure updates are captured
  // const {
  //   data: response,
  //   error,
  //   isLoading,
  // } = useGetAllCategoriesWithSubcategoriesQuery(undefined, {
  //   skip: false, // Always fetch to ensure categories are up-to-date
  //   refetchOnMountOrArgChange: true, // Refetch when component mounts or args change
  //   refetchOnReconnect: true, // Refetch when browser reconnects
  //   refetchOnFocus: true, // Refetch when window gains focus
  // });

  const fetchCategories = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL_NEW}/public/categories`
    );
    console.log(res, "res");
    setAllCategories(res?.data?.results);
    // if (res?.data?.results) {
    //   dispatch(setCategories(res?.data?.results)); // Always update Redux with new data
    // }
  };

  // Memoize the categories based on changes in storedCategories or response
  // const categories = useMemo(() => storedCategories, [storedCategories]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId, subcategoryId = "") => {
    navigate(
      `/products?category_id=${categoryId}${
        subcategoryId ? `&subcategory_id=${subcategoryId}` : ""
      }`
    );
  };

  const handleMouseEnter = () => {
    clearTimeout(timerRef.current);
    setOpenMenu(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setOpenMenu(false);
    }, 300);
  };

  return (
    <li
      className="menu-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a href="#" className="menu-link">
        {t("Products")}
      </a>
      <div className={`mega-menu ${openMenu ? "show" : ""}`}>
        <div className="mega-menu-content">
          <div className="categories-column">
            {allCategoreis?.map((cat) => (
              <div
                key={cat.id}
                className="category-item"
                onMouseEnter={() => setHoveredCategory(cat)}
                onClick={() => handleCategoryClick(cat.id)}
              >
                {/* Render the icon as an image if iconName is an SVG file */}
                {cat.Image ? (
                  <img
                    src={`${cat.Image}`}
                    alt={`${cat.name_en} icon`}
                    className="category-icon"
                  />
                ) : (
                  <span className="default-icon">🔍</span> // Default icon if not found
                )}
                <span style={{ marginLeft: "20px" }}>{cat.name_en}</span>{" "}
              </div>
            ))}
          </div>

          <div className="subcategories-column">
            {hoveredCategory ? (
              hoveredCategory?.subcategories?.map((sub) => (
                <div key={sub.id} className="subcategory-item">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCategoryClick(hoveredCategory.id, sub.id);
                    }}
                  >
                    {sub.name_en}
                  </a>
                </div>
              ))
            ) : (
              <p className="subcategory-placeholder">Hover over a category</p>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default MegaMenu;
