import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Divider, Button, RadioGroup, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateCartMutation,
  useGetCartQuery,
  useDeleteCartMutation,
  useShippingStatusMutation,
} from "../../redux/Apis/Cart/index";
import { useTranslation } from 'react-i18next';
import ShippingAddressForm from "./ShippingAddressForm";
import OrderDetails from "./OrderDetails";
import "./CheckoutCart.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCreditCard from "./StripeCreditCard"; // Assuming you have this component
import { useCheckUserOrdersMutation, useCreateOrderMutation } from "../../redux/Apis/Order";
import axios from "axios";
import { Card, CardContent, Typography, Container, Grid, Box } from '@mui/material';
import loadgif from "../../shared/images/loadinggif.gif"
import { ArrowBigLeft } from "lucide-react";
import { useGetApplyKupongMutation } from "../../redux/Apis/Coupon";
import { useDeleteUserAddressMutation, useGetUserAddressesQuery, useUpdateUserAddressMutation } from "../../redux/Apis/User";
import Loader from "../Loader/Loader";
import { useGetLatestExchangeRateQuery } from "../../redux/Apis/Exchange";
import { toast } from 'react-toastify';
import { clearCart } from "../../redux/Slices/Cart/index";
import countryData from '../Cart/Country.json';  // Assuming your country file is in the same directory
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; // Import the package
import AutoComplete from "../Account/Autocomplete";

const CheckoutCart = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cartSlice.items);
  const { isLoggedIn, userId, user } = useSelector((state) => state.authSlice);
  const { data: cartApiResponse, refetch, isLoading, isError } = useGetCartQuery(userId, { skip: !isLoggedIn || !userId });
  const cartData = cartApiResponse?.data;
  const [orginalPrice, setOriginalPrice] = useState(0)
  const APIKEY = process.env.REACT_APP_GOOGLE_API

  const { data: shippingAddresses, refetch: refetchShippingAddresses, isLoading: isShippingLoading, isError: isShippingError } = useGetUserAddressesQuery(userId, {
    refetchOnMountOrArgChange: true, // Refetch on mount or if the userId changes
    keepUnusedDataFor: 0, // Do not keep unused data
  });
  const [applyKupong, { data, error, isLoadingKupong }] = useGetApplyKupongMutation();
  const [createCart] = useCreateCartMutation();
  const [deleteCart] = useDeleteCartMutation();
  const [createShipping] = useShippingStatusMutation();
  const [cartDeleted, setCartDeleted] = useState(false);
  const [createOrder] = useCreateOrderMutation();
  const [checkOrder, { dataCheck, error2, isLoadingCheck }] = useCheckUserOrdersMutation();
  // State for payment processing
  const [clientSecret, setClientSecret] = useState("");
  const [showPayment, setShowPayment] = useState(false); // New state to control payment visibility

  const { data: dataExchange, errorExchange, isLoadingExchange } = useGetLatestExchangeRateQuery();
  const [rate, setRate] = useState(null); // State to store the rate
  useEffect(() => {

    if (dataExchange?.success) {
      setRate(dataExchange.rate.rate); // Store the rate in state
    }
  }, [dataExchange]); // Run effect when dataExchange changes

  // Shipping address and selected shipping state
  const [shippingAddress, setShippingAddress] = useState({
    addressLine1: "",
    postalCode: "",
    country: "se",
    phone: "",
  });
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [discount, setDiscount] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false); // New state variable
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [cards, setCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [updateUserAddress, { isLoading: isUpdating, isError: isUpdatingError }] = useUpdateUserAddressMutation();
  const [deleteUserAddress, { isLoading: isDeleting, isError: isDeleteError }] = useDeleteUserAddressMutation();
  const [updateAddress, setUpdateAddress] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cheapest, setCheapeast] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await checkOrder({ userId });

        setFirstTime(response.data?.hasOrders)

      } catch (error) {
        console.error("Error checking orders:", error);
      }
    };

    fetchOrders();
  }, [userId, checkOrder]);


  const payload = useMemo(() => ({
    userId,
    items: cartItems.map((item) => ({
      productId: item.productId,
      sku: item.sku,
      quantity: item.quantity,
      scale: item.size,
      isBigBuyProduct: item.isBigBuyProduct,
    })),
  }), [userId, cartItems]);

  const shippingPayload = useMemo(() => ({
    cart: {
      userId,
      items: cartItems.map((item) => ({
        sku: item.sku,
        quantity: item.quantity,
        scale: item.size,
        isBigBuyProduct: item.isBigBuyProduct,
      })),
    },
    isoCountry: shippingAddress.country,
    postcode: shippingAddress.postalCode,
  }), [userId, cartItems, shippingAddress]);

  useEffect(() => {
    const deleteExistingCart = async () => {
      if (userId && cartData?._id) {

        await deleteCart(cartData._id).unwrap();
        setCartDeleted(true);

      } else {
        setCartDeleted(true);
      }
    };
    deleteExistingCart();
  }, [userId, cartData, deleteCart]);

  useEffect(() => {
    const createNewCart = async () => {
      if (userId && cartItems.length > 0 && cartDeleted) {
        try {
          await createCart(payload).unwrap();
          await refetch();
        } catch (error) {
          // Handle error
        }
      }
    };
    createNewCart();
  }, [userId, cartItems, createCart, refetch, cartDeleted, payload]);

  const [loading, setLoading] = useState(false); // Add loading state

  useEffect(() => {
    const createShippingRequest = async () => {
      setLoading(true); // Start loading
      try {
        const response = await createShipping(shippingPayload).unwrap();
        console.log("response", response);
        setShippingOptions(response?.shippingOptions || []);
        setCheapeast(response?.cheapestShippingOptions || [])

      } catch (error) {
        console.error("Error creating shipping request:", error);
        setShippingOptions([]);
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
    };

    // Check if the postal code length is 5 and country exists
    if (shippingAddress.postalCode.length === 5 && shippingAddress.country) {
      createShippingRequest();
    } else {
      setShippingOptions([]);
      if (showPayment) {
        setShowPayment(false);
        setClientSecret(""); // Hide payment component and go back to shipping
      }
    }
  }, [shippingAddress.postalCode, shippingAddress.country, createShipping]);


  // Function to handle selecting a shipping option
  const handleSelectShippingOption = (event) => {
    // Get the selected shipping option object
    const selectedOption = shippingOptions.find(
      (option) => option.shippingService.id === event.target.value
    );
    setSelectedShippingOption(selectedOption); // Set the full object
  };

  // Function to handle navigation back to the cart
  const handleGoBackToCart = () => {
    nav("/cart");
  };

  const handleAddressChange = useCallback((field, value) => {

    setShippingAddress((prev) => ({ ...prev, [field]: value }));

  }, []);

  const [savedCards, setSavedCards] = useState([])
  const [showExisiting, setShowExisinf] = useState(false)
  const [paymentIntentId, setPaymentIntentId] = useState(null)

  const handleNext = async () => {


    if (!selectedShippingOption) {
      setErrors({ shipping: "Please select a shipping option." });

      return; // Early return if no shipping option is selected
    }

    const totalAmount = parseFloat(totalPrice); // Assuming totalPrice is in currency units
    ;

    try {
      const test = totalAmount.toFixed(2) * 100


      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment/create-payment-intent`, {
        amount: parseInt(totalAmount),
        userId: userId, // Add userId to the request body
      });





      setClientSecret(response.data.clientSecret);
      setShowPayment(true); // Show payment component
    } catch (error) {

    }
  };

  const handleNextExisitng = async () => {


    if (!selectedShippingOption) {
      setErrors({ shipping: "Please select a shipping option." });

      return; // Early return if no shipping option is selected
    }

    const totalAmount = parseFloat(totalPrice); // Assuming totalPrice is in currency units
    ;

    setShowExisinf(true);

    try {
      const test = totalAmount.toFixed(2) * 100


      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment/create-payment-intent`, {
        amount: parseInt(totalAmount),
        userId: userId, // Add userId to the request body
      });




      setPaymentIntentId(response.data.paymentIntentId);
      setClientSecret(response.data.clientSecret);
      setShowPayment(true); // Show payment component
    } catch (error) {

    }
  };

  const handleBack = () => {

    // Optionally, you can also hide the payment component if you're in the payment step
    if (showPayment) {
      setShowPayment(false);
      setClientSecret(""); // Hide payment component and go back to shipping
    }

    // If needed, clear any errors
    setErrors({});
  };

  const [couponCode, setCouponCode] = useState("");

  const [isCouponApplied, setIsCouponApplied] = useState(false); // New state for tracking coupon application

  const handleApplyCoupon = async () => {

    
    // Create an array of products with productId and quantity for big buy products
    const products = cartItems
      .filter((item) => item.isBigBuyProduct)
      .map((item) => ({
        productId: item.productId,
        quantity: item.quantity,
      }));
  
    if (!couponCode) {
      alert("Please enter a coupon code.");
      return;
    }
  
    try {

      const response = await applyKupong({
        code: couponCode,
        products: products, // Send the products array with productId and quantity
        userId: userId,
      }).unwrap();
  
      if (response.success && response.data.isValid) {
        setDiscount((response.data.totalDiscount).toFixed(2) || 0);
        setIsCouponApplied(true); // Set to true when coupon is successfully applied
      } else {
        setDiscount(0);
        setIsCouponApplied(false); // Reset if coupon is invalid
      }
    } catch (error) {
      console.error("Error applying discount:", error);
      setDiscount(0);
      setIsCouponApplied(false); // Reset on error
    }
  };
  

  const BASE_URL = process.env.REACT_APP_BASE_URL

  const getUserCards = async () => {
    try {
      const response = await fetch(`${BASE_URL}/payment/get-user-cards`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch cards');
      }

      const data = await response.json();
      setCards(data.paymentMethods || []); // Assuming the response contains a `cards` field
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserCards();
  }, [userId]); // Run when the component mounts or userId changes


  const totalPrice = useMemo(() => {
    const basePrice = cartData?.totalPrice || 0;
    const shippingCost = selectedShippingOption
      ? selectedShippingOption.cost || 0 // Use the full object directly
      : 0;

    const originalPriceTotat = basePrice + shippingCost;
    setOriginalPrice(originalPriceTotat.toFixed(2));

    // Use `firstTime` to determine the additional discount
    const existingDiscount = discount || 0;
    const additionalDiscount = !firstTime ? basePrice * 0.10 : 0; // Apply 10% additional discount if firstTime is false

    const totalDiscount = existingDiscount + additionalDiscount;
    const discountedPrice = basePrice + shippingCost - totalDiscount;

    return discountedPrice > 0 ? discountedPrice.toFixed(2) : "0.00";
  }, [cartData, selectedShippingOption, discount, firstTime, isLoadingCheck]);


  if (isShippingLoading) {
    return <div><Loader /></div>;
  }

  if (isShippingError) {
    return <div>Error loading shipping addresses.</div>;
  }

  const addresses = shippingAddresses?.data;




  const handleNewAddressChange = (event) => {
    const { name, value } = event.target;
    setShippingAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddNewAddress = () => {
    setSelectedAddress(null)
    // Reset the shippingAddress for a new entry
    setShippingAddress({
      addressLine1: "",
      postalCode: "",
      country: "se",
      phone: "",
    });
    setIsAddingNewAddress(true); // Show the form for a new address
  };

  const handleSelectAddress = (address) => {
    setShippingAddress({
      addressLine1: address.addressLine1,
      postalCode: address.postalCode,
      country: address.country,
      phone: address.phone,
    });
    setSelectedAddress(address);
    setIsAddingNewAddress(false); // Hide the form when an existing address is selected
  };



  const handlePayNow = async () => {
    try {
      setLoader(true);

      // Send payment confirmation request
      const response = await fetch(`${BASE_URL}/payment/confirm-payment-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentIntentId: paymentIntentId,
          paymentMethodId: selectedCardId,
        }),
      });

      // If response is not OK, show error and return
      if (!response.ok) {
        const errorMessage = `Payment failed with status: ${response.status}`;
        toast.error(errorMessage);
        return;
      }

      // Parse the response data
      const data = await response.json();

      // Extract relevant payment data
      const { paymentIntent: { id: paymentId, status: paymentStatus } = {} } = data;
      const orderShipping = shippingAddress;

      console.log("cheapest", cheapest);

      const orderPayload = {
        paymentId,
        userId,
        items: cartData?.items.map(item => ({
          productId: item.productId,
          totalItemPrice: item.totalItemPrice,
          quantity: item.quantity,
          price: item.price,
        })),
        shippingOption: selectedShippingOption,
        totalAmount: totalPrice,
        cheapestOptions: cheapest,
        paymentMethod: "card",
        paymentStatus,
        shippingAddress: orderShipping,
      };



      // Create order
      const responseOrder = await createOrder(orderPayload);

      if (responseOrder.error) {
        toast.error(t("Error creating order. Please try again."));
        return;
      }

      // Clear the cart and remove it from the database
      dispatch(clearCart());
      await deleteCart(cartData?._id).unwrap();

      // Redirect to success page
      const orderDetails = encodeURIComponent(JSON.stringify(responseOrder));
      nav(`/auth/order-success?orderDetails=${orderDetails}`, { replace: true });
      toast.success(t("Order created successfully!"));

      setShowExisinf(false);

    } catch (error) {
      console.error('Error during payment confirmation:', error);
      toast.error(t("An error occurred. Please try again."));
    } finally {
      setLoader(false);
    }
  };

  const handleCardSelection = (cardId, cardDetails) => {
    setSelectedCardId(cardId);
    setSelectedCard(cardDetails);
  };

  const handleGoBack = () => {
    setSelectedCardId(null);
    setSelectedCard(null);
  };

  const handlePlaceSelect = (place) => {
    // Set the address line 1 based on the place selected
    const addressLine1 = place?.structured_formatting?.main_text;
    setUpdateAddress((prev) => ({
      ...prev,
      addressLine1,
    }));
  };

  if (loader)
    return <div>
      <Loader />
    </div>

  // Run when the component mounts or userId changes


  const handleCardSelect = (id) => {
    setSelectedCardId(id);
  };

  const handleSelectAddress2 = (address) => {
    // Extract address components and concatenate them into a full address
    const fullAddress = address.address_components
        .map(component => component.long_name) // Extract the long name from each component
        .join(', '); // Join all parts with commas


    // Set the full address in the state
    setUpdateAddress((prevState) => ({
        ...prevState,
        addressLine1: fullAddress, // Set the full address
    }));
};



  const handleEditAddress = (address) => {
    setShippingAddress({
      addressLine1: "",
      postalCode: "",
      country: "se",
      phone: "",
    });
    setSelectedShippingOption(null);
    setSelectedAddress(null);
    setUpdateAddress(address);
    setEditDialogOpen(true);
  };

  const handleDeleteAddress = (addressId) => {
    setShippingAddress({
      addressLine1: "",
      postalCode: "",
      country: "se",
      phone: "",
    });
    setSelectedShippingOption(null);
    setSelectedAddress(null);
    setUpdateAddress(addressId);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = () => setEditDialogOpen(false);
  const handleCloseDeleteDialog = () => setDeleteDialogOpen(false);

  const handleSaveEditedAddress = async () => {
    if (updateAddress) {
      try {
        await updateUserAddress({
          userId: userId,
          addressId: updateAddress._id,
          addressLine1: updateAddress.addressLine1,
          postalCode: updateAddress.postalCode,
          phone: updateAddress.phone,
        }).unwrap(); // Ensure the mutation is fulfilled
        setUpdateAddress(null);
        // Close the dialog after successful update
        setEditDialogOpen(false);
        refetchShippingAddresses();
      } catch (error) {
        console.error("Failed to update address:", error);
        // Optionally, handle the error (e.g., show a notification)
      }
    }
  };

  const handleConfirmDelete = () => {
    if (updateAddress) { // Ensure elem.id exists
      deleteUserAddress({ userId, addressId: updateAddress })
        .unwrap() // If you want to handle the response
        .then(() => {
          // Refetch the shipping addresses after a successful delete
          refetchShippingAddresses();
        })
        .catch((error) => {
          console.error("Error deleting address:", error);
        });
    }
    setDeleteDialogOpen(false);
  };

  return (
    <div className="checkout-container">
      <div className="checkout-left">
        <div className="user-info">
          <p className="user-greeting">{t("Hello")}</p>
          <h1 className="user-name">{user?.first_name} {user?.last_name}</h1>
        </div>
        {!firstTime && (
          <div className="discount-message" style={{ marginBottom: '20px', color: '#28a745', fontWeight: 'bold' }}>
            You receive a 10% discount on your first order!
          </div>
        )}
        {isAddingNewAddress ? (
          <ShippingAddressForm
            shippingAddress={shippingAddress}
            onAddressChange={handleAddressChange}
            errors={errors}
          />
        ) : (
          <>
            {addresses && addresses.length > 0 ? (
              <div>
                <h3>Select an Existing Shipping Address</h3>
                <div className="address-cards">

                  {addresses.map((address) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={address._id}>
                      <Card
                        style={{
                          position: 'relative',
                          margin: '10px',
                          padding: '15px',
                          boxShadow: selectedAddress?._id === address._id ? '0px 4px 12px rgba(25, 118, 210, 0.2)' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          borderRadius: '10px',
                          border: '1px solid #f0f0f0',
                          backgroundColor: selectedAddress?._id === address._id ? '#e3f2fd' : '#fff',
                          height: '100%', // Ensures all cards have equal height in the grid layout
                        }}
                      >
                        <CardContent>
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{address.addressLine1}</Typography>
                          <Typography variant="body2" color="textSecondary">{address.postalCode}, {address.country}</Typography>
                          <Typography variant="body2" color="textSecondary">Phone: {address.phone}</Typography>

                          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '10px' }}>
                            <Button
                              variant="contained"
                              color={selectedAddress?._id === address._id ? 'primary' : 'secondary'}
                              onClick={() => handleSelectAddress(address)}
                              style={{
                                borderRadius: '20px',
                                backgroundColor: selectedAddress?._id === address._id ? '#1976d2' : '#f0f0f0',
                                color: selectedAddress?._id === address._id ? '#fff' : '#000',
                                flex: '1 1 auto', // Responsive button width
                                minWidth: '100px',
                              }}
                            >
                              {selectedAddress?._id === address._id ? 'Selected' : 'Select'}
                            </Button>

                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={(e) => { e.stopPropagation(); handleEditAddress(address); }}
                              style={{
                                borderRadius: '20px',
                                flex: '1 1 auto', // Responsive button width
                                minWidth: '100px',
                              }}
                            >
                              Edit
                            </Button>

                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={(e) => { e.stopPropagation(); handleDeleteAddress(address._id); }}
                              style={{
                                borderRadius: '20px',
                                flex: '1 1 auto', // Responsive button width
                                minWidth: '100px',
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}


                </div>
                <Button variant="contained" onClick={handleAddNewAddress}>
                  Add New Address
                </Button>
              </div>
            ) : (
              <div>
                <h3>No shipping addresses found.</h3>
                <Button variant="contained" onClick={handleAddNewAddress}>
                  Add New Address
                </Button>
              </div>
            )}

            <Dialog open={editDialogOpen} onClose={handleCloseEditDialog} style={{ zIndex: 1000 }}>
              <DialogTitle>Edit Address</DialogTitle>
              <DialogContent>
                {/* Google Places Autocomplete for Address Line 1 with custom styles */}
                <AutoComplete onSelectAddress={handleSelectAddress2} />

                {/* Other address fields */}
                <TextField
                  label="Postal Code"
                  fullWidth
                  margin="normal"
                  value={updateAddress?.postalCode || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only 5 digits
                    if (/^\d{0,5}$/.test(value)) {
                      setUpdateAddress((prev) => ({ ...prev, postalCode: value }));
                    }
                  }}
                  inputProps={{
                    maxLength: 5, // Limit the input to 5 characters
                  }}
                />

                <TextField
                  label="Phone"
                  fullWidth
                  margin="normal"
                  value={updateAddress?.phone || ""}
                  onChange={(e) => setUpdateAddress((prev) => ({ ...prev, phone: e.target.value }))}
                />
                {isUpdatingError && <p>Error updating address.</p>}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditDialog} color="primary">Cancel</Button>
                <Button onClick={handleSaveEditedAddress} color="primary" disabled={isUpdating}>
                  {isUpdating ? "Saving..." : "Save"}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <Typography>Are you sure you want to delete this address?</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
                <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
              </DialogActions>
            </Dialog>

          </>
        )}

        <OrderDetails cartData={cartData} />
        <Button variant="outlined" color="primary" onClick={handleGoBackToCart} className="go-back-button">
          {t("GoBackCart")}
        </Button>
      </div>


      <div className="checkout-right">
        {showPayment ? (
          <>

          </>
        ) : (
          <>
            <div className="apply-coupon">
              <h4 className="coupon-title">{t("Apply Coupon")}</h4>
              <div className="coupon-input-container">
                <input
                  type="text"
                  placeholder={t("Enter your coupon code")}
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className="coupon-input"
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleApplyCoupon}
                  className="apply-coupon-button"
                  disabled={isCouponApplied} // Disable if the coupon was successfully applied
                >
                  {t("Apply")}
                </Button>
              </div>


            </div>
          </>
        )}
        {showPayment ? (
          <>


            <div className="back-div">
              <Button

                onClick={() => {
                  handleBack();
                }}
                className="back-button"
              >
                <ArrowBigLeft />
                Shipping
              </Button>
              <h4 className="checkout-title">{t("PayOptions")}</h4>

            </div>

            <Divider className="divider" />

            {showExisiting ? (
              <>
                <Container>
                  <Typography variant="h5" gutterBottom>
                    Your Cards
                  </Typography>

                  {/* Display selected card */}
                  {selectedCard ? (
                    <Box mb={2}>
                      <Typography variant="h6">Selected Card</Typography>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="h6">{selectedCard.card.brand}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            **** **** **** {selectedCard.card.last4}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {selectedCard.card.exp_month}/{selectedCard.card.exp_year}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGoBack}
                            fullWidth
                            style={{ marginTop: '10px' }}
                          >
                            Change Card
                          </Button>
                        </CardContent>
                      </Card>
                    </Box>
                  ) : (
                    <></>
                  )}

                  {/* Display available cards if none is selected */}
                  {!selectedCard && cards.length > 0 ? (
                    <Grid container spacing={2}>
                      {cards.map((card, index) => (
                        <Grid item xs={12} key={index}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h6">{card.card.brand}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                **** **** **** {card.card.last4}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {card.card.exp_month}/{card.card.exp_year}
                              </Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCardSelection(card.id, card)}
                                fullWidth
                                style={{ marginTop: '10px' }}
                              >
                                Select Card
                              </Button>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <>
                      {selectedCard ? (
                        <></>
                      ):(
                        <p>No cards found</p>
                      )} 
                    </>
                  )}

                  {/* Pay Now Button */}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handlePayNow(selectedCardId)}
                    disabled={!selectedCardId} // Disable button if no card is selected
                    fullWidth
                    style={{ marginTop: '20px' }}
                  >
                    Pay Now
                  </Button>

                  {/* Button to add a new card */}
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => setShowExisinf(false)}
                    style={{ marginTop: '10px' }}
                  >
                    Enter New Card
                  </Button>
                </Container>
              </>
            ) : (
              <>
                {clientSecret && (
                  <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)} options={{ clientSecret }}>
                    <StripeCreditCard
                      clientSecret={clientSecret}
                      amount={totalPrice}
                      userId={userId}
                      createOrder={createOrder} // Assuming you have this function
                      cartItems={cartData?.items || []}
                      shippingAddress={shippingAddress}
                      cartID={cartData?._id}
                      cards={savedCards}
                      shippingOption={selectedShippingOption}
                      cheapestOptions={cheapest}
                    />
                  </Elements>


                )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowExisinf(true)}
                >
                  Use an existing card
                </Button>
              </>
            )}


            <div className="cart-pricing">
              <p>{t("Total")}</p>
              <p>{`${totalPrice} kr`}</p>
            </div>
          </>
        ) : (
          <>
            <h4 className="checkout-title">{t("Shipping Options")}</h4>
            <Divider className="divider" />

            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <img src={loadgif} alt="Loading..." style={{ width: '50px', height: '50px' }} />
              </div>
            ) : (
              <RadioGroup
                value={selectedShippingOption?.shippingService.id} // Ensure selectedShippingOption is the full object
                onChange={handleSelectShippingOption}
                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                {shippingOptions.length > 0 ? (
                  shippingOptions.map((option) => (
                    <Card
                      key={option.shippingService.id}
                      style={{
                        cursor: 'pointer',
                        transition: '0.3s',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 4px 20px rgba(0, 0, 0, 0.2)')}
                      onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '0 2px 10px rgba(0, 0, 0, 0.1)')}
                    >
                      <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                          value={option.shippingService.id}
                          control={<Radio color="primary" />}
                          label={
                            <div style={{ flex: 1 }}>
                              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                {option.shippingService.name}
                              </Typography>
                              <Typography variant="body2" style={{ color: 'gray' }}>
                                {option.shippingService.delay}
                              </Typography>
                              <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                {option.cost.toFixed(2)} kr
                              </Typography>
                            </div>
                          }
                        />
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <p>{t("No Shipping Options Available")}</p>
                )}
              </RadioGroup>


            )}

            {errors.shipping && <p className="error">{errors.shipping}</p>}

            {discount > 0 &&
              <div className="cart-pricing">
                <p>Kupong Dicount</p>
                <p>{discount} kr</p>
              </div>
            }

            {firstTime === false &&
              <div className="cart-pricing">
                <p>Total Before Discount</p>
                <p>{`${orginalPrice} kr`}</p>

              </div>
            }


            <div className="cart-pricing">
              <p>{t("Total")}</p>
              <p>{`${totalPrice} kr`}</p>

            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {

                handleNextExisitng();
              }}
              className="next-button"
              disabled={!selectedShippingOption}
            >
              Use an existing card
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={!selectedShippingOption}
              onClick={() => {

                handleNext();
              }}
              className="next-button"
            >
              Pay with new Card
            </Button>

          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(CheckoutCart);
