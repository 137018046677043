import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Success.css"; // Ensure you create this file with the CSS below
import { useTranslation } from 'react-i18next';
const OrderSuccess = () => {
    const location = useLocation();
    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const orderDetailsParam = queryParams.get('orderDetails');

        if (orderDetailsParam) {
            try {
                const details = JSON.parse(decodeURIComponent(orderDetailsParam));
                setOrderDetails(details.data.data);
            } catch (error) {
                console.error("Error parsing order details:", error);
            }
        }
    }, [location]);


    const { t } = useTranslation();

    if (!orderDetails) return <p>Loading...</p>;
    const {
        orderShortId = "N/A",
        totalAmount = 0,
        paymentId = "N/A",
        paymentMethod = "N/A",
        paymentStatus = "N/A",
        shippingStatus = "N/A",
        shippingAddress = {},
    } = orderDetails;

    const {
        addressLine1 = "N/A",
        city = "N/A",
        state = "N/A",
        postalCode = "N/A",
        country = "N/A",
    } = shippingAddress;

    return (
        <div className="order-success animated fadeIn">
            <div className="order-success-container">
                <h1 className="order-heading">🎉 {t("OrderCOnfirm")}</h1>
                <p className="order-message">
                    {t("ThanKYou")} <strong>{orderShortId}</strong>.
                </p>

                <div className="order-summary animated slideInLeft">
                    <h2>
                        {t("OrderSummary")}
                    </h2>
                    <p><strong>{t("TotalAmount")}</strong> {totalAmount ? totalAmount.toFixed(2) : '0.00'} kr</p>
                    <p><strong>{t("PayID")}</strong> {paymentId}</p>
                    <p><strong>{t("PayMethod")}</strong> {paymentMethod}</p>
                    <p><strong>{t("PayStatus")}</strong> {paymentStatus}</p>
                    <p><strong>{t("ShipStatus")}</strong> {shippingStatus}</p>
                </div>

                <div className="shipping-address animated slideInRight">
                    <h2>{t("ShipAdd")}</h2>
                    <p>{addressLine1}</p>
                    <p>{postalCode}</p>
                  
                </div>

                <p className="expected-delivery">🚚 {t("ExpDev")}</p>

                <Link to="/" className="continue-shopping btn-animated">
                    {t("ContinueShopping")}
                </Link>
            </div>
        </div>
    );
};

export default OrderSuccess;
