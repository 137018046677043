import React, { useEffect, useState } from "react";
import "./ProductDetailsImage.css";
import VerticalSlider from "./VerticalSlider";

const ProductDetailsImage = ({ images }) => {
  const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL; // Get the base URL from env

  // Use the first image from the prop as the default current image
  const [currentImage, setCurrentImage] = useState(images && images.length > 0 ? images[0] : null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [isZoomed, setIsZoomed] = useState(false); // State to manage zoom functionality

  useEffect(() => {
    if (images && images.length > 0) {
      setCurrentImage(images[0]); // Set the first image as default
    }
  }, [images]);

  const handleSelectImage = (img) => {
    setCurrentImage(img); // Update the current image when a new one is selected
  };

  const handleImageClick = () => {
    setIsModalOpen(true); // Open the modal on image click
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
    setIsZoomed(false); // Reset zoom when modal closes
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed); // Toggle zoom on image click
  };

  return (
    <div className="product-details-images-container">
      {images && images.length > 0 ? (
        <>
          <div className="product-details-slider">
            <VerticalSlider images={images} handleSelectImage={handleSelectImage} />
          </div>
          <div className="product-details-image" onClick={handleImageClick}>
            
            
            <img src={`${currentImage}`} alt="Product" />
          </div>

          {/* Modal for enlarged image */}
          {isModalOpen && (
            <div className="modal-overlay" onClick={handleCloseModal}>
              <div className="modal-content">
                <img
                  src={`${currentImage}`}
                  alt="Enlarged Product"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent closing when clicking on the image
                    toggleZoom(); // Toggle zoom on click
                  }}
                  className={isZoomed ? "zoomed" : ""} // Apply zoomed class when isZoomed is true
                />
                <button className="modal-close-button" onClick={handleCloseModal}>
                  &times;
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <p>No images available</p>
      )}
    </div>
  );
};

export default ProductDetailsImage;
