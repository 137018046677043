import { Avatar, IconButton, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import profileImg from "../../shared/images/profile.jpg";
import "./Account.css";
import AccountSidebar from "./AccountSideBar";
import AddressForm from "./AddressForm";
import PersonalInformation from "./PersonalInformation";
import Returns from "./Returns";
import MyOrder from "../MyOrders/MyOrder";
import SavedCards from "./Cards";
import AddCard from "./AddCard";

const Account = () => {

  const { user } = useSelector(state => state.authSlice)
  const location = useLocation();

  const renderComponent = () => {
    switch (location.pathname) {
      case "/account":
        return <PersonalInformation />;
      case "/address-details":
        return <AddressForm />;
      case "/returns":
        return <Returns />;
      case "/my-order":
        return <MyOrder />;
      case "/cards":
        return <SavedCards />;
      case "/add-card":
        return <AddCard />;
      // Add more cases as needed
      // Add more cases as needed
      default:
        return <PersonalInformation />; // Default to PersonalInformation if no match
    }
  };
  return (
    <div className="account-section">
      <div className="account-container">
        <div>
          <div className="account-details profile-name shadow-sm">
            <Tooltip>
              <IconButton sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={profileImg} />
              </IconButton>
            </Tooltip>
            <div>
              <p>Hello,</p>
              <h1>{user?.first_name} {user?.last_name}</h1>
            </div>
          </div>
          <div className="account-details profile-sidebar-container shadow-sm">
            <AccountSidebar />
          </div>
        </div>
        <div className="account-details shadow-sm">
          <div className="personal-info-container">{renderComponent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Account;
