import React, { useState, useEffect } from "react";
import { TextField, Grid, Autocomplete } from "@mui/material";
import { useTranslation } from 'react-i18next';
import countryData from '../Cart/Country.json';  // Assuming your country file is in the same directory
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'; // Import the package
import AutoComplete from "../Account/Autocomplete";

const ShippingAddressForm = React.memo(({ shippingAddress, onAddressChange, errors }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);

  const APIKEY = process.env.REACT_APP_GOOGLE_API

  useEffect(() => {
    // Load countries data from JSON file
    setCountries(countryData);
  }, []);

  // Custom postal code handler to ensure only 5 digits
  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,5}$/.test(value)) { // Only allow digits and limit to 5
      onAddressChange("postalCode", value);
    }
  };

  const handleSelectAddress2 = (address) => {
    // Extract address components and concatenate them into a full address

    const fullAddress = address.address_components
      .map((component) => component.long_name) // Extract the long name from each component
      .join(', '); // Join all parts with commas



    // Send the full address back via onAddressChange
    onAddressChange("addressLine1", fullAddress);
  };

  return (
    <div className="shipping-section">
      <h4 className="checkout-title">{t("ShipAdd")}</h4>
      <h5 className="checkout-title">Vi skickar endast till Sverige</h5>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutoComplete onSelectAddress={handleSelectAddress2} />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Postal Code *"
            value={shippingAddress.postalCode}
            onChange={handlePostalCodeChange}
            error={!!errors.postalCode}
            helperText={errors.postalCode}
            fullWidth
            margin="none" // Change this as needed
            inputProps={{ maxLength: 5 }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Phone Number *"
            value={shippingAddress.phone}
            onChange={(e) => onAddressChange("phone", e.target.value)}
            error={!!errors.phone}
            helperText={errors.phone}
            fullWidth
            margin="none" // Change this as needed
          />
        </Grid>
      </Grid>

    </div>
  );
});

export default ShippingAddressForm;
