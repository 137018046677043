import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const ReturnsRefundPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Typography variant="h4" gutterBottom>
        Returns and Refund Policy
      </Typography>
      <Typography variant="body1" paragraph>
        This Returns and Refund Policy ("Policy") is part of the Terms and
        Conditions of Minasatkom AB ("we," "us," "our"). It outlines the
        conditions and procedures for returning products purchased from our
        online store and obtaining a refund. By making a purchase, you
        ("customer," "you," "your") agree to comply with this Policy.
      </Typography>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          1. Eligibility for Returns
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Timeframe: Products must be returned within 14 days from the date of receipt." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Condition of Products: To be eligible for a return, products must be unused, in the same condition that you received them, and in their original packaging." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Non-Returnable Items: Certain items are not eligible for return, including but not limited to perishable goods, customized items, gift cards, and downloadable software products. These will be clearly marked on our Website." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          2. Return Process
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Initiating a Return: To initiate a return, contact our customer service team at info@Minasatkom.com with your order number and details of the product you wish to return. We will provide you with a Return Merchandise Authorization (RMA) number and instructions on how to proceed." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• The customer will also be able to initiate the return process using his own user account. However, he will not return any of his products without receiving return approval from us." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Return Shipping: You are responsible for paying the shipping costs for returning your item. Shipping costs are non-refundable unless the return is due to an error on our part (e.g., you received an incorrect or defective item)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Return Authorization: All returns must be authorized by our customer service team and accompanied by an RMA number. Unauthorized returns will not be accepted." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          3. Inspection and Approval
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Inspection of Returns: Once your return is received and inspected, we will notify you of the approval or rejection of your refund. This process typically takes 14 days." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Approval Criteria: Products must meet our eligibility criteria to be approved for a refund. Items that are damaged, not in their original packaging, or show signs of use will not be eligible for a refund." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          4. Refund Process
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Processing Time: If your return is approved, your refund will be processed within 14 days of our receipt of the returned product." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Refund Method: Refunds will be issued to the original method of payment used for the purchase. If this is not possible, we will contact you to arrange an alternative method." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Partial Refunds: In certain situations, only partial refunds are granted (e.g., items that are returned after the return period or items that are not in their original condition)." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          5. Exchanges
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Exchanges: If you need to exchange a product for the same item due to size, color, or other reasons, please contact our customer service team. Exchanges are subject to product availability. However, all costs arising from this change (returning the original item and sending the replacement item) will be borne by the customer." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          6. Damaged or Defective Products
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Reporting Damages: If you receive a damaged or defective product, please contact us immediately at service@Minasatkom.com with your order number and a description of the issue." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Replacement or Refund: We will arrange for a replacement or refund at no additional cost to you, provided the damage or defect is reported within 30 days of receipt." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          7. Non-Refundable Items
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="• Gift Cards: Gift cards are non-refundable." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Downloadable Software: Digital products that have been downloaded are non-refundable." />
          </ListItem>
          <ListItem>
            <ListItemText primary="• Sale Items: Only regular priced items may be refunded; sale items cannot be refunded." />
          </ListItem>
        </List>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          8. Contact Information
        </Typography>
        <Typography variant="body1">
          Customer Service: For any questions or concerns regarding this Policy,
          please contact our customer service team at{" "}
          <strong>service@Minasatkom.com</strong>.
        </Typography>
      </Box>
    </Container>
  );
};

export default ReturnsRefundPolicy;
